import React, { Component } from 'react';
import '../css/App.scss';
import Transition from '../components/Transition';
import Menu from '../components/Menu';
import Project from '../components/Project_box';
import projectsInfo from '../constants/projectsInfo';

class Projects extends Component {
    componentDidMount() {}

    render() {
        return (
            <div className="App">
                <Menu />
                <Transition name="PROJECTS" />
                <div className="projects-container">
                    <h1>Projects.</h1>
                    <div className="featured-projects-contain">
                        {projectsInfo.map((proj, i) => (
                            <Project
                                key={i}
                                url={proj.url}
                                imgurl={proj.img}
                                projname={proj.name}
                                desc={proj.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Projects;
