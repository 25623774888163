import '../css/App.scss';
import '../css/photos.scss';
import Transition from '../components/Transition';
import Menu from '../components/Menu';
import React, { useState, useCallback } from 'react';
// import ReactGA from 'react-ga';
import { photos } from '../constants/photos';
import { GalleryPhoto, GridPhoto } from '../components/PhotosComponents';
import { isMobile } from '../utils/detection';
import sr from '../utils/ScrollRevealMod.js';
import { scroll_config_photos } from '../constants/scrollConfig';
import ReactGA from 'react-ga';

const PhotoViewer = (props) => {
    const photo = props.selectedPhoto;
    const url = photo?.src;
    if (!photo || !url) return null;

    const title = photo.title || '';

    const removePhotoViewer = () => {
        props.clearSelectedPhoto();
    };

    return (
        <div className={'photo-viewer'} onClickCapture={removePhotoViewer}>
            <GalleryPhoto photo={photo} url={url} title={title} />
            {/* <button className="back-button" onClick={props.clearSelectedPhoto}>
                X
            </button> */}
            <div className="nav-buttons-container">
                <button className="nav-button" onClick={props.previousPhoto}>
                    ◄
                </button>
                <button className="nav-button" onClick={props.nextPhoto}>
                    ►
                </button>
            </div>
        </div>
    );
};

const PhotosColumn = (props) => {
    return (
        <div className="photos-column">
            {props.photoArray.map((p, i) => {
                const id = `col-${props.colIndex}-photo-${i}`;
                if (!isMobile) {
                    setTimeout(() => {
                        sr.reveal(`.${id}`, scroll_config_photos, 100);
                    }, 1000);
                }

                return (
                    <GridPhoto
                        key={id}
                        id={id}
                        index={i}
                        photo={p}
                        photosWidth={props.photosWidth}
                        setImageInViewer={props.setImageInViewer}
                    />
                );
            })}
        </div>
    );
};

// logic to divide photo array into n arrays
const splitPhotoArray = (numCols, photos) => {
    let photoArrays = new Array(numCols);
    for (let i = 0; i < photos.length; i++) {
        if (i % numCols === 0) {
            if (!photoArrays[0]) photoArrays[0] = [];
            photoArrays[0].push(photos[i]);
        } else {
            if (!photoArrays[i % numCols]) photoArrays[i % numCols] = [];
            photoArrays[i % numCols].push(photos[i]);
        }
    }
    return photoArrays;
};

const PhotosGrid = React.memo((props) => {
    const numCols = props.numCols;
    const photos = props.photos;
    const photosWidth = isMobile ? 500 : 640;
    let photoArrays = splitPhotoArray(numCols, photos);

    let className = 'photos-grid';
    // only 2 and 3 cols supported, default is 2
    if (numCols === 2) {
        className += ' photos-grid-two';
    } else if (numCols === 3) {
        className += ' photos-grid-three';
    }

    return (
        <div className={className}>
            {photoArrays.map((arr, colIndex) => {
                return (
                    <PhotosColumn
                        className={`column-${colIndex}`}
                        photoArray={arr}
                        key={colIndex}
                        colIndex={colIndex}
                        setImageInViewer={props.setImageInViewer}
                        photosWidth={photosWidth}
                    />
                );
            })}
        </div>
    );
});

const PhotosPage = () => {
    let numCols = 3;

    if (isMobile) {
        numCols = 1;
    }

    const [selectedPhoto, setSelectedPhoto] = useState({});
    const setImageInViewer = useCallback((photo) => {
        ReactGA.event({
            category: 'photos',
            action: 'photo clicked',
            label: photo.title,
        });
        setSelectedPhoto(photo);
    }, []);

    const clearSelectedPhoto = () => setSelectedPhoto({});

    const nextPhoto = () => {
        let currentPhotoIndex;
        let nextPhotoIndex;
        photos.map((photo, i) => {
            if (photo.src === selectedPhoto.src) {
                currentPhotoIndex = i;
            }
            return null;
        });

        if (currentPhotoIndex === photos.length - 1) {
            nextPhotoIndex = 0;
        } else {
            nextPhotoIndex = currentPhotoIndex + 1;
        }
        ReactGA.event({
            category: 'photos',
            action: 'gallery next button',
            label: '',
        });
        setSelectedPhoto(() => ({
            ...photos[nextPhotoIndex],
        }));
    };

    const previousPhoto = () => {
        let currentPhotoIndex;
        let previousPhotoIndex;
        photos.map((photo, i) => {
            if (photo.src === selectedPhoto.src) {
                currentPhotoIndex = i;
            }
            return null;
        });
        if (currentPhotoIndex === 0) {
            previousPhotoIndex = photos.length - 1;
        } else {
            previousPhotoIndex = currentPhotoIndex - 1;
        }
        ReactGA.event({
            category: 'photos',
            action: 'gallery previous button',
            label: '',
        });
        setSelectedPhoto(() => ({
            ...photos[previousPhotoIndex],
        }));
    };

    return (
        <div className="App">
            <Menu defaultColor={true} />
            <Transition name="PHOTOS" />
            <div className="photos-container">
                <div className="photos-header">
                    <h1>Photos.</h1>
                    <p>
                        One of my innate tendencies is to capture the world around me through{' '}
                        <a className="red" href={process.env.PUBLIC_URL + '/writes'}>
                            writing
                        </a>
                        ,{' '}
                        <a className="red" href={process.env.PUBLIC_URL + '/videos'}>
                            creating videos
                        </a>
                        , or by taking photographs. This page gives a glimpse of a few of my
                        cherished experiences over the past few years.
                    </p>
                    {isMobile && <p>This page is best viewed on a desktop.</p>}
                </div>

                {/* Currently no way to get HD photos on mobile */}
                {!isMobile && selectedPhoto && (
                    <PhotoViewer
                        selectedPhoto={selectedPhoto}
                        photos={photos}
                        clearSelectedPhoto={() => clearSelectedPhoto()}
                        nextPhoto={() => nextPhoto()}
                        previousPhoto={() => previousPhoto()}
                    />
                )}
                <PhotosGrid numCols={numCols} photos={photos} setImageInViewer={setImageInViewer} />
            </div>
        </div>
    );
};

export default PhotosPage;
