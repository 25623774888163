import React, { Component } from 'react';
import Transition from '../components/Transition';
import Menu from '../components/Menu';
import sr from '../utils/ScrollRevealMod.js';
import books from '../constants/books';
import { isMobile } from '../utils/detection';
import '../css/reads.scss';

class Reads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: isMobile,
        };
    }
    componentDidMount() {
        const scroll_config = {
            origin: 'bottom',
            duration: 700,
            delay: 100,
            distance: '100px',
            scale: 1,
            easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
            mobile: true,
            reset: false,
            useDelay: 'once',
        };

        sr.reveal('.reads-content>p', scroll_config);
    }

    render() {
        return (
            <div className="App">
                <Menu />
                <Transition name="READS" />
                <div className="reads-container">
                    <div className="reads-content">
                        <h1>Reads.</h1>
                        <p className="reads-content-line">
                            I love reading books about diverse topics. Here's a list of some of my
                            favorite books that I've read over the past few years and why I liked
                            them.
                        </p>
                        {books.map((book, id) => {
                            let coverImg = '';
                            // if (!this.state.mobile) {
                            if (book.cover) {
                                coverImg = (
                                    <div className="book-cover">
                                        <img src={book.cover} alt={book.name} loading="lazy" />
                                    </div>
                                );
                            }
                            // }
                            return (
                                <div className="reads-content-book-container" key={id}>
                                    {coverImg}
                                    <div className="book-details">
                                        <h2>{book.name}</h2>
                                        <h4>{book.author}</h4>
                                        {book.description}
                                    </div>
                                </div>
                            );
                        })}
                        <p>
                            <i>More coming soon. Stay tuned!</i>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reads;
