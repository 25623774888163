const writes = [
    {
        title: 'Powering Millions of Real-Time Decisions with LyftLearn Serving',
        url:
            'https://eng.lyft.com/powering-millions-of-real-time-decisions-with-lyftlearn-serving-9bb1f73318dc',
    },
    {
        title: 'Full-Spectrum ML Model Monitoring at Lyft',
        url: 'https://eng.lyft.com/full-spectrum-ml-model-monitoring-at-lyft-a4cdaf828e8f',
    },
    {
        title: 'A Taste of Americana',
        url:
            'https://mihirmathur.medium.com/a-taste-of-americana-ccbc7130ce18?sk=9c6ff14d296d35918c9196f38b0bbae7',
    },
    {
        title: 'Speeding Ahead with a Systematic Approach to Web Performance',
        url:
            'https://eng.lyft.com/speeding-ahead-with-a-systematic-approach-to-web-performance-282b6cf8ae2',
    },
    {
        title: 'A (Very) Brief Story of the English Language',
        url:
            'https://mihirmathur.medium.com/a-very-brief-story-of-the-english-language-9bc4ab0ef29c',
    },
    {
        title: 'Half a Decade at UCLA',
        url: 'https://mihirmathur.medium.com/half-a-decade-at-ucla-1b3eaeb686aa',
    },
    {
        title: 'A Silicon Valley Summer',
        url: 'https://mihirmathur.medium.com/a-silicon-valley-summer-299e595ffc3c',
    },
    {
        title: 'Halfway through UCLA',
        url: 'https://mihirmathur.medium.com/halfway-through-ucla-7c4f7c0e7814',
    },
    {
        title: 'VisualizingLA and Product Management for Creative Labs',
        url: 'https://mihirmathur.medium.com/visualizingla-ce8e023243e5',
    },
    {
        title: '7 things I learned at my Thomson Reuters Internship',
        url:
            'https://mihirmathur.medium.com/7-things-i-learned-at-my-thomson-reuters-internship-59aa6ee7917d',
    },
    {
        title: 'The Optimists | UCLA Bruin Blog',
        url: 'https://blog.admissions.ucla.edu/2017/05/05/the-optimists-mihir-mathur/',
    },
];

export default writes;
