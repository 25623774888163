import $ from 'jquery';

export function upDown(selector, duration, callback) {
    $(selector + '>span').transition(
        {
            top: '0%',
        },
        duration,
        'cubic-bezier(.34,.16,.31,.91)'
    );
    if (typeof callback === 'function') {
        callback();
    }
}

export function downUp(selector, duration, callback) {
    $(selector + '>span').transition(
        {
            top: '100%',
        },
        duration,
        'cubic-bezier(.34,.16,.31,.91)'
    );
    if (typeof callback === 'function') {
        callback();
    }
}

export function slideFromLeft(selector, callback) {
    $(selector + '>span').transition(
        {
            left: '0%',
            opacity: 1,
        },
        2000,
        'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    );
    if (typeof callback === 'function') {
        callback();
    }
}

export function dramaticAppear(selector, text, classnm, callback) {
    var chars = text.split('');
    $.each(chars, function (i, val) {
        $(selector).append('<div class="' + classnm + '"><span>' + val + '</span></div>');
    });
    if (typeof callback === 'function') {
        callback();
    }
}

export function lineSlide(selector, wth, hth, color, callback) {
    $(selector).append(
        '<div class="interaction-line-container"><div class="interaction-line"></div></div>'
    );
    $('.interaction-line-container').css({
        width: wth,
        height: hth,
        position: 'relative',
        overflow: 'hidden',
    });

    $('.interaction-line').css({
        width: wth,
        height: hth,
        backgroundColor: color,
        position: 'absolute',
        left: '-100%',
    });
    $('.interaction-line')
        .transition(
            {
                left: '0%',
            },
            2000,
            'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        )
        .transition(
            {
                left: '100%',
                delay: 500,
            },
            1000,
            'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        );

    if (typeof callback === 'function') {
        callback();
    }
}

$(document).ready(function () {
    console.log('Document ready');
});
