export const scroll_config = {
    origin: 'bottom',
    duration: 1000,
    delay: 20,
    distance: '100px',
    scale: 1,
    easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
    mobile: true,
    reset: false,
    useDelay: 'once',
};

export const scroll_config_photos = {
    origin: 'bottom',
    duration: 500,
    delay: 10,
    distance: '30px',
    scale: 1,
    easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
    mobile: true,
    reset: false,
    useDelay: 'once',
};

export const scroll_config_noreset = {
    origin: 'bottom',
    duration: 1000,
    delay: 150,
    distance: '100px',
    scale: 1,
    easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
    mobile: true,
    reset: false,
    useDelay: 'once',
};

export const scroll_config_noreset_short = {
    origin: 'bottom',
    duration: 1000,
    delay: 0,
    distance: '30px',
    scale: 1,
    easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
    mobile: true,
    reset: false,
    useDelay: 'once',
};
