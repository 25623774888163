import proj_vizla from '../projects/visualizingla.jpg';
import proj_heapsort from '../projects/heapsort.png';
import proj_alexa from '../projects/alexa.png';
import proj_acm from '../projects/acm.png';
import proj_ymaa from '../projects/ymaa.jpg';
import proj_gitcured from '../projects/gitcured.png';
import proj_almari from '../projects/almari.png';
import proj_fluentones from '../projects/fluentones.png';
import proj_nextfest from '../projects/nextfest.png';
import proj_old from '../projects/oldwebsite.png';
import proj_nomad from '../projects/nomad.jpg';
import proj_asd from '../projects/asd.jpg';
import proj_churro from '../projects/churro.jpg';
import proj_examplecheck from '../projects/examplecheck.jpg';
import proj_ted from '../projects/ted.jpg';
import proj_mlops22 from '../projects/mlopsworld22.jpg';
import proj_webjshrink from '../projects/webjshrink.jpg';
import thesis from '../projects/thesis.jpg';

export const projectsInfo = [
    {
        name: 'MLOps World 2022',
        url: 'https://drive.google.com/file/d/1uut3wtZsdai7-ih_m6jS70XDKeN14BuR/view',
        description: "'Defending Against Decision Degradation with Full-Spectrum Model Monitoring'",
        img: proj_mlops22,
    },
    {
        name: 'TEDxUCLA',
        url: 'https://www.youtube.com/watch?v=q_p8TN9xwmc',
        description: "'Why everyone should hack'",
        img: proj_ted,
    },
    {
        name: 'MS Thesis',
        url: 'https://mihirmathur.com/thesis.pdf',
        description:
            'Leveraging Distributed Tracing and Container Cloning for Replay Debugging of Microservices',
        img: thesis,
    },
    {
        name: 'WebJShrink',
        url: 'https://mihirmathur.com/fse20-webjshrink.pdf',
        description:
            'A Web Service for Debloating Java Bytecode. Konner Macias, Mihir Mathur, Bobby R. Bruce, Tianyi Zhang, and Miryung Kim. ACM ESEC/FSE ’20. ',
        img: proj_webjshrink,
    },
    {
        name: 'Nomad',
        url: 'https://www.youtube.com/watch?v=Rhl0HH_OGVg&',
        description: 'Gamifying travel and encouraging adventure.',
        img: proj_nomad,
    },
    {
        name: 'ExampleCheck',
        url: 'https://mihirmathur.com/esec_fse18.pdf',
        description:
            "Augmenting Stack Overflow with API Usage Patterns Mined from GitHub, Anastasia Reinhardt, Tianyi Zhang, Mihir Mathur, Miryung Kim, ACM ESEC/FSE '18 Research Demonstrations",
        img: proj_examplecheck,
    },
    {
        name: 'Churro',
        url: 'https://codyleyhan.github.io/churro/',
        description:
            'Application that enables roommates to manage chore distribution and tracking while playing a game.',
        img: proj_churro,
    },
    {
        name: 'Visualizing LA',
        url: 'https://mihirmathur.github.io/visualizingla/',
        description:
            "This project aims to tell a story of Los Angeles' transportation through data visualization. I was the product manager.",
        img: proj_vizla,
    },
    {
        name: 'Autism Spectrum Disorder Classification',
        url: 'https://mihirmathur.com/asd_classification.pdf',
        description: 'Machine Learning techniques on fMRI images for classifying autism.',
        img: proj_asd,
    },

    {
        name: 'Heapsort',
        url: 'https://github.com/Mihirmathur/Heapsort',
        description:
            "A concept for efficiently sorting waste into 'Compost', 'Landfill' and 'Recyclables' using Computer Vision.",
        img: proj_heapsort,
    },
    {
        name: 'Alexa Portfolio Manager',
        url: 'https://www.youtube.com/watch?v=8z0mSWjlcZc',
        description:
            "Amazon Echo skill that lets you conveniently manage your investment portfolio with voice commands. Built at MHacks using Blackrock's Alladin.",
        img: proj_alexa,
    },
    {
        name: 'UCLA ACM Website and Design',
        url: 'http://acm.cs.ucla.edu',
        description: "Design and development of UCLA ACM's website.",
        img: proj_acm,
    },
    {
        name: 'YMAA Website',
        url: 'http://www.theyouthmovement.org/#/',
        description: "Web development for The Youth Movement Against Alzheimer's.",
        img: proj_ymaa,
    },
    {
        name: 'Nextfest 2017 Website',
        url: 'https://www.behance.net/gallery/48665667/NextFest-2017-Homepage',
        description: 'Managed design and development of website for Nextfest 2017.',
        img: proj_nextfest,
    },
    {
        name: 'Gitcured',
        url: 'https://devpost.com/software/treehacks-health',
        description:
            'GitCured is a platform for patients to discuss diseases, ask questions about complexities, know key disease statistics and interact with other patients. This web app was built at TreeHacks, Stanford.',
        img: proj_gitcured,
    },
    {
        name: 'Fluentones',
        url: 'http://mihirmathur.github.io/Fluentones/',
        description:
            'Fluentones is a tool for encrypting text based messages using colors. It takes a text message as input and generates an image containing a sequence of colors. This image can be decrypted to get the original message back.',
        img: proj_fluentones,
    },
    {
        name: 'Almari',
        url: 'https://devpost.com/software/almari-a-virtual-wardrobe',
        description:
            'Almari is a crowd-sourced virtual wardrobe that attempts to redefine luxury clothing. It won the Best Social Media Hack award by Gap at CalHacks 2.0 at UC Berkeley. Almari provides a web platform for high fashion products to be shared and thus better utilized.',
        img: proj_almari,
    },
    {
        name: 'mihirmathur.com V1',
        url: 'http://mihirmathur.com/v1',
        description: 'My old website. Built in early 2015.',
        img: proj_old,
    },
];

export default projectsInfo;
