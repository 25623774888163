import { isMobileOnly } from 'react-device-detect';
import { useState, useEffect } from 'react';

export const isMobile = isMobileOnly;

// export const userAgent = getUA;

export const isDevelopment = process.env.NODE_ENV === 'development';

export const isWebGlSupported = () => {
    // Create canvas element. The canvas is not added to the
    // document itself, so it is never displayed in the
    // browser window.
    var canvas = document.createElement('canvas');
    // Get WebGLRenderingContext from canvas element.
    var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
        return true;
    } else {
        return false;
    }
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};
