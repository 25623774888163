import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './base.scss';

// if moving to Github pages again, might need HashRouter and need to update menuRoutes
// import { HashRouter as Router, Route } from 'react-router-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import GA from './utils/GoogleAnalytics';
import Projects from './pages/Projects';
import Reads from './pages/Reads';
import Writes from './pages/Writes';
import Design from './pages/Designs';
import Videos from './pages/Videos';
import Photos from './pages/Photos';

const AppComponent = lazy(() => import('./App'));
// const GalleryPage = lazy(() => import('./pages/Gallery'));
// const renderLoader = () => <div className="spinner">Loading mihirmathur.com</div>;

ReactDOM.render(
    <Suspense fallback="">
        <Router>
            {GA.init() && <GA.RouteTracker />}
            <Route exact path="/" component={AppComponent}></Route>
            <Route path="/projects" component={Projects}></Route>
            <Route path="/reads" component={Reads}></Route>
            <Route path="/books" component={Reads}></Route>
            <Route path="/writes" component={Writes}></Route>
            <Route path="/design" component={Design}></Route>
            <Route path="/videos" component={Videos}></Route>
            <Route path="/photos" component={Photos}></Route>
            {/* <Suspense fallback="">
                <Route path="/gallery" component={GalleryPage}></Route>
            </Suspense> */}
        </Router>
    </Suspense>,
    document.getElementById('main')
);
