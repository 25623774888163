import React, { useState } from 'react';
import Transition from '../components/Transition';
import Menu from '../components/Menu';
import '../css/videos.scss';
import videos from '../constants/videos';
import ReactGA from 'react-ga';

const Videos = () => {
    const [videoId, setVideoId] = useState(videos[0].embedId);
    const changePlayerVideo = (embedId, title) => {
        setVideoId(embedId);
        ReactGA.event({
            category: 'video',
            action: 'video clicked',
            label: title,
        });
    };
    return (
        <div className="App">
            <Menu defaultColor={true} />
            <Transition name="VIDEOS" />
            <div className="videos videos-container">
                <div className="videos-player">
                    <iframe
                        title="video-player"
                        className="videos-iframe"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                    />
                </div>
                <div className="videos-carousel">
                    {videos.map((video, i) => {
                        return (
                            <div
                                className="videos-carousel-item"
                                key={i}
                                onClick={() => changePlayerVideo(video.embedId, video.title)}>
                                <img alt={video.title} src={video.cover} loading="lazy" />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Videos;
