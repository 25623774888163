import munich from '../assets/videoCovers/munichsalzburg.jpg';
import ucla from '../assets/videoCovers/ucla.jpg';
import caligirl from '../assets/videoCovers/californiagirl.jpg';
import chicago from '../assets/videoCovers/chicago.jpg';
import seattle from '../assets/videoCovers/seattle.jpg';
import lisbon from '../assets/videoCovers/lisbon.jpg';
import kealakekua from '../assets/videoCovers/kealakekua.jpg';
import tokyo from '../assets/videoCovers/tokyo.jpg';
import lakearrowhead from '../assets/videoCovers/lakearrowhead.jpg';
import yosemite from '../assets/videoCovers/yosemite.jpg';
import siliconvalley from '../assets/videoCovers/siliconvalley.jpg';

const videos = [
    {
        title: 'Kealakekua Bay',
        cover: kealakekua,
        embedId: 'ODKC-H62To4',
    },
    {
        title: 'Lisbon',
        cover: lisbon,
        embedId: 'jlEK59RLYQg',
    },
    {
        title: 'UCLA Aerial Tour',
        cover: ucla,
        embedId: 'Il_D7_sJ2gc',
    },
    {
        title: 'Munich & Salzburg',
        cover: munich,
        embedId: '6-31j2qZpwY',
    },
    {
        title: 'California Girl',
        cover: caligirl,
        embedId: '7BWZtPCNa9I',
    },
    {
        title: 'Tokyo',
        cover: tokyo,
        embedId: 'HD2AnPur18M',
    },
    {
        title: 'Lost in Chicago',
        cover: chicago,
        embedId: 'AQShKvnwfzQ',
    },
    {
        title: 'Seattle',
        cover: seattle,
        embedId: 'RC3yhoq5hXw',
    },
    {
        title: 'A Silicon Valley Summer',
        cover: siliconvalley,
        embedId: 'SIsfr5-wS-s',
    },
    {
        title: 'Yosemite',
        cover: yosemite,
        embedId: 'lcFC-DLMH8A',
    },
    {
        title: 'UCLA ACM Retreat - Lake Arrowhead',
        cover: lakearrowhead,
        embedId: 'EFM_JFrmKtI',
    },
];

export default videos;
