import React, { Component } from 'react';
import '../css/App.scss';
import '../css/featuredProjects.scss';
import Transition from '../components/Transition';
import Menu from '../components/Menu';
import Project from '../components/Project_box';
import des_esuc from '../design/esuc.jpg';
import des_dykstra from '../design/dykstra.png';
import des_xino from '../design/xino.png';
import des_bnp from '../design/bnp.png';
import des_batman from '../design/batman.gif';
import des_acm from '../design/acmlogo.gif';

class Designs extends Component {
    componentDidMount() {}

    render() {
        return (
            <div className="App">
                <Menu />
                <Transition name="DESIGN" />
                <div className="projects-container">
                    <div className="projects-description">
                        <h1>Design</h1>
                        <p className="projects-line">
                            In high-school and in college, I dabbled a bit with motion design,
                            user-interface design, apparel design, and video. I hope to pick up
                            design at some point again. Here are a few things I designed in the
                            past.
                        </p>
                    </div>
                    <div className="featured-projects-contain">
                        <Project
                            imgurl={des_esuc}
                            projname="ESUC 2016 T-Shirt"
                            desc="This tshirt was created for the Engineering Society of UCLA and given out to all the incoming engineers at UCLA in 2016."
                        />
                        <Project
                            imgurl={des_dykstra}
                            projname="Dykstra 5 T-Shirt"
                            desc="This tshirt was created for the UCLA's Global Health floor - Dykstra Hall 5. It represents North America with medical instruments."
                        />
                        <Project
                            imgurl={des_xino}
                            projname="XINO 2015 T-Shirt"
                            desc="This tshirt was made for all members and alumni of XINO, DPS Rohini's Computer Science club."
                        />
                        <Project
                            imgurl={des_bnp}
                            projname="BrownN'Proud T-Shirt"
                            desc="Designed for Brownn'Proud, an NGO which aims to remove the stigmas and social standards which makes one feel inferior about oneself. This T-Shirt was part of a fund-raiser campaign of Brownn'Proud."
                        />
                        <Project
                            imgurl={des_acm}
                            projname="ACM Branding"
                            desc="Did complete rebranding for ACM and its five committees."
                        />
                        <Project
                            imgurl={des_batman}
                            projname="Batman Walkcycle"
                            desc="This walk-cycle was made with Illustrator and After Effects."
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Designs;
