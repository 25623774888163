import React, { Component } from 'react';
import '../css/projectBox.scss';

class Project extends Component {
    componentDidMount() {}

    render() {
        return (
            <a href={this.props.url} target="_blank" rel="noopener noreferrer">
                <div className="featured-project">
                    <img className="projImg" src={this.props.imgurl} alt={this.props.projname} />
                    <div className="projDesc">
                        <h3>{this.props.projname}</h3>
                        <p>{this.props.desc}</p>
                    </div>
                </div>
            </a>
        );
    }
}

export default Project;
