const menuRoutes = [
    {
        name: 'HOME',
        route: process.env.PUBLIC_URL + '/',
    },
    {
        name: 'VIDEOS',
        route: process.env.PUBLIC_URL + '/videos',
    },
    {
        name: 'PHOTOS',
        route: process.env.PUBLIC_URL + '/photos',
    },
    {
        name: 'READS',
        route: process.env.PUBLIC_URL + '/reads',
    },
    {
        name: 'WRITES',
        route: process.env.PUBLIC_URL + '/writes',
    },
    {
        name: 'PROJECTS',
        route: process.env.PUBLIC_URL + '/projects',
    },
    {
        name: 'DESIGN',
        route: process.env.PUBLIC_URL + '/design',
    },
    // {
    //     name: 'GALLERY',
    //     route: process.env.PUBLIC_URL + '/#/gallery',
    // },
];

export default menuRoutes;
