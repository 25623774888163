import React, { Component } from 'react';
import '../css/menu.scss';
// eslint-disable-next-line
import transition from 'jquery.transit';
import * as interactions from './interactions';
import { isMobile } from '../utils/detection';
import $ from 'jquery';
import ReactGA from 'react-ga';
import menuRoutes from '../constants/menuRoutes';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { menuOverlay: 0 };
        this.triggerOverlay = this.triggerOverlay.bind(this);
    }

    triggerOverlay() {
        if (this.state.menuOverlay === 0) {
            $('.menu-overlay').css({ display: 'flex' });
            $('.tcon-menu__lines').addClass('colorchange');
            $('.menu-overlay').transition(
                {
                    left: '0%',
                },
                700,
                'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
            );

            let size = $('.menu-item').length;
            let str;
            for (let i = 1; i <= size; i++) {
                str = '.menu-item:nth-child(' + i + ')';

                (function (str, i) {
                    setTimeout(function () {
                        interactions.upDown(str, 1000);
                    }, i * 100);
                })(str, i);
            }

            this.setState({ menuOverlay: 1 });
            ReactGA.event({
                category: 'menu',
                action: 'menu opened',
                label: 'menu',
            });
        } else {
            var size = $('.menu-item').length;
            var str;
            var timeCount = 0;
            for (var i = size; i >= 0; i--) {
                str = '.menu-item:nth-child(' + i + ')';
                timeCount += i * 50;
                (function (str, i) {
                    setTimeout(function () {
                        // console.log(str + " " + i);
                        interactions.downUp(str, 500);
                    }, (size - i) * 100);
                })(str, i);
            }

            setTimeout(function () {
                $('.menu-overlay').transition(
                    {
                        left: '100%',
                    },
                    700,
                    'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
                );
            }, timeCount);
            if (!this.props.defaultColor) {
                setTimeout(function () {
                    $('.tcon-menu__lines').removeClass('colorchange');
                }, timeCount + 500);
            }

            this.setState({ menuOverlay: 0 });
        }
    }

    componentDidMount() {
        var transformScript = "<script>transformicons.add('.tcon')</script>";
        $('.menu').append(transformScript);
        $('.menu-button').transition(
            { opacity: 1 },
            200,
            0,
            'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        );
    }

    addBackground(e) {
        const key = e.target.getAttribute('data-key');
        const overlayClass = `menu-overlay-image-${key}`;
        const bgimage = document.getElementsByClassName(overlayClass)[0];
        bgimage.style.opacity = 0.08;
        bgimage.style.transform = 'scale(1)';
    }

    removeBackground(e) {
        const key = e.target.getAttribute('data-key');
        const overlayClass = `menu-overlay-image-${key}`;
        const bgimage = document.getElementsByClassName(overlayClass)[0];
        bgimage.style.opacity = 0;
        bgimage.style.transform = 'scale(1.1)';
    }

    render() {
        let linesClassName = 'tcon-menu__lines';

        // defaultColor = true is set on black bg pages
        if (this.props.defaultColor) {
            linesClassName += ' colorchange';
        }
        return (
            <div className="menu">
                <div className="menu-button">
                    <button
                        onClick={this.triggerOverlay}
                        type="button"
                        className="tcon tcon-menu--arrow tcon-menu--arrowup"
                        aria-label="toggle menu">
                        <span className={linesClassName} aria-hidden="true"></span>
                        <span className="tcon-visuallyhidden">toggle menu</span>
                    </button>
                </div>
                <div id="menu-overlay" className="menu-overlay">
                    {isMobile
                        ? menuRoutes.map((item, i) => {
                              return (
                                  <div key={i} className="menu-item">
                                      <span>
                                          <a href={item.route}>{item.name}</a>
                                      </span>
                                  </div>
                              );
                          })
                        : menuRoutes.map((item, i) => {
                              return (
                                  <div
                                      key={i}
                                      className="menu-item"
                                      data-key={item.name}
                                      onMouseEnter={this.addBackground}
                                      onMouseLeave={this.removeBackground}>
                                      <span data-key={item.name}>
                                          <a data-key={item.name} href={item.route}>
                                              {item.name}
                                          </a>
                                      </span>
                                  </div>
                              );
                          })}
                    {isMobile
                        ? ''
                        : menuRoutes.map((item, i) => (
                              <div
                                  key={i}
                                  className={`menu-overlay-image menu-overlay-image-${item.name}`}></div>
                          ))}
                </div>
            </div>
        );
    }
}

export default Menu;
