import React, { Component } from 'react';
import '../css/App.scss';
import * as interactions from './interactions';
import $ from 'jquery';

class Transition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: props.name || '',
        };
        console.log(this.state.text);
    }

    componentDidMount() {
        var scope = this;
        window.scrollTo(0, 0);
        $('body').addClass('stop-scrolling');
        $('body').bind('touchmove', function (e) {
            e.preventDefault();
        });
        $('.contain').transition(
            {
                left: '0%',
                opacity: 1,
            },
            700,
            'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        );

        // Commented out to get transition to work on firefox
        // $('.contain:before').transition(
        //     {
        //         left: '0%',
        //         opacity: 1,
        //     },
        //     700,
        //     'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        // );

        setTimeout(function () {
            interactions.dramaticAppear(
                '.drama',
                scope.state.text,
                'interaction-dramatic',
                function () {
                    interactions.slideFromLeft('.interaction-dramatic', function () {
                        interactions.lineSlide('.drama', '15%', '5px', 'white');
                    });
                }
            );
        }, 200);

        $('.contain').transition(
            {
                opacity: 0,
                delay: 2000,
            },
            2000,
            'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        );

        setTimeout(function () {
            $('.contain').remove();
            $('body').removeClass('stop-scrolling');
            $('body').unbind('touchmove');
        }, 5000);
    }

    render() {
        return (
            <div className="contain">
                <div className="drama updown" id="upD2"></div>
            </div>
        );
    }
}

export default Transition;
