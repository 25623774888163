export const books = [
    {
        name: `A Long Petal of The Sea`,
        author: 'Isabel Allende',
        description: `A Long Petal of The Sea is a historical fiction novel following the paths of two Spanish people,
        Victor Delmau, an army doctor, and Roser, a pregnant widow who flee the Spanish Civil War. The plot is centered
        around Victor and Roser’s reluctant marriage for the sake of survival and their lives over several decades as
        refugees in Chile. The storyline is deeply intertwined with several historical events such as Pablo Neruda’s
        SS Winnipeg transporting Spanish refugees and Pinochet’s dictatorship of Chile. Through a vivid portrayal of hope,
        love, the refugee experience, the pain inflicted by humans on humans, and freedom, Allende’s writing enthralled
        me and evoked many emotions. `,
        cover: 'https://covers.openlibrary.org/b/id/11453160-M.jpg',
    },
    {
        name: `In Defense of Food: An Eater's Manifesto`,
        author: 'Michael Pollan',
        description: `In Defense of Food is a critical examination of American food and eating habits, which is rapidly becoming
        the world’s diet. Michael Pollan argues that “nutritionism” (i.e. nutrients matter, not the food) is the official ideology
        of Western diet and this diet is responsible for several chronic diseases such as coronary heart disease, stroke, and cancer,
        which are leading causes of death. In Defense of Food defends real food against faulty nutrition science and the food industry
        that produces edible food like substances. Through examples spanning food marketing, faulty research, and food politics that
        have influenced what we consume over the past five decades, Pollan convincingly demonstrates how American diet is
        fundamentally broken. He then gives a maxim for an actually healthy diet: “Eat Food. Not too much. Mostly plants”.
        This book is not only very informative, but it is also an entertaining read. After reading about the nutritional-industrial
        complex and all the forces that influence what I eat, I approach buying groceries differently and I've become more conscious
        of what and how I eat.`,
        cover: 'https://covers.openlibrary.org/b/id/9246366-M.jpg',
    },
    {
        name: 'The Ride of a Lifetime',
        author: 'Robert Iger',
        description: `The Ride of a Lifetime is an autobiography that takes the reader on a captivating journey through former Disney CEO
        Bob Iger’s life and career. By giving a play by play breakdown of his career starting at the very bottom and rising to the top of
        one of the largest entertainment companies, Iger provides valuable insights into principles for leadership, fostering creativity,
        forming relationships, managing risk, and making hard decisions. I particularly enjoyed learning about the rationale and
        challenges of Disney’s acquisitions of Pixar, Lucasfilm, Marvel, and 20th Century Fox. It was also enlightening to see Iger’s
        thought process during difficult decisions, for instance when Disney backed out of acquiring Twitter at the last second.
        The Ride of a Lifetime was not only an entertaining read, but also taught me several lessons on leadership and navigating challenges
        at work.`,
        cover: 'https://covers.openlibrary.org/b/id/10355182-M.jpg',
    },
    {
        name: 'Framers: Human Advantage in an Age of Technology and Turmoil',
        author: 'Kenneth Cukier, Viktor Mayer-Schönberger, Francis de Véricourt',
        description: `In Framers, Cuckier, Mayer-Schönberger, and de Véricourt present the different “frames” with which
        humans see the world from distinct vantage points. Through numerous examples including algorithmic discovery of antibiotics,
        Ben Bernanke’s actions during the 2008 financial crisis, the #MeToo movement, and Messner & Habeler’s alpine climbing of
        Mt. Everest, this book show how different frames have led to breakthroughs in science, economics, social movements, and
        other areas. On the other hand, misapplying frames can have horrendous consequences, such as Lysenkoism (which applied
        a communist frame to agriculture) leading to the Soviet famine. This book also discusses the unique human advantage
        over AI because of innate causal models, the ability to think in counterfactuals, and see and work around constraints.
        This book taught me how to apply different frames to a problem, encouraged me to avoid mental monocultures, and showed
        me that framing offers human beings the scarcest of treasures: hope.`,
        cover: 'https://covers.openlibrary.org/b/id/11274436-M.jpg',
    },
    {
        name: 'Under a White Sky',
        author: 'Elizabeth Kolbert',
        description: `In Under The White Sky, Pulitzer Prize winner, Elizabeth Kolbert, examines the transformation of nature
        in the Anthropocene epoch by showcasing how humans have used innovative technologies to advance their environment.
        Kolbert shifts the climate change conversations that typically center around examples of technological modifications
        adverse to Earth, to recent positive technological interventions that will likely save the environment. I particularly
        enjoyed the journey through the world that this book takes you on while providing illustrative examples—from the reversal
        of the Chicago river to the assisted evolution of corals at the Great Barrier Reef to the negative carbon emissions plants
        in Iceland. This book provided me a fresh perspective on climate change, and introduced me to the tools and techniques that
        leading scientists and engineers are using to shape the world we'll live in.`,
        cover: 'https://covers.openlibrary.org/b/id/10501777-M.jpg',
    },
    {
        name: 'A Wild Sheep Chase',
        author: 'Haruki Murakami',
        description: `A Wild Sheep Chase by Haruki Murakami is a novel that blends surrealism, myth, mystery, and metaphysics.
        The story follows an unnamed narrator, an advertising executive, whose life changes when he receives a postcard with a
        mutant sheep on it. He's forced to embark on a mission to find the sheep to avoid facing dire consequences. The quest
        for the sheep takes him through the mountains of Hokkaido, on a journey in which he discovers himself. I enjoyed the
        writing which was simple to read yet complex to fully understand since it combined a mystery and romance story with
        dreamy magical fantasy, symbolism, and philosophical touches.`,
        cover: 'https://covers.openlibrary.org/b/id/11522007-M.jpg',
    },
    {
        name: 'The Sympathizer',
        author: 'Viet Thanh Nguyen',
        description: `The Sympathizer is a beautifully written novel in which an unnamed protagonist,
        a North Vietnamese mole in the South Vietnamese army, recounts his experiences in the form of a confession.
        This Pulitzer Prize winning book meanders through many themes: war, exile, the immigrant experience, identity,
        communism and capitalism, and is sprinkled with dark humor, witty sentences, and shrewd observations of Americanness.
        I learned from this book's historicity, such as the depiction of the fall of Saigon. Some of my favorite lines were:
        "Remember that the best medical treatment is a sense of relativism. No matter how badly you might feel, take comfort
        in knowing there's someone who feels much worse" and "It is always better to admire the best among our foes rather
        than the worst among our friends".`,
        cover: 'https://covers.openlibrary.org/b/id/11247876-M.jpg',
    },
    {
        name: 'The Dutch House',
        author: 'Ann Patchett',
        description: `The Dutch House by Ann Patchett is a tale of two siblings, Danny and Maeve Conroy,
        over a span of five decades. The saga revolves around a fairytale-esque mansion and a dysfunctional
        family and its impact on the intimate relationship between a brother and a sister. Nostalgia,
        the bond between siblings, and parenthood are the central themes. I particularly enjoyed the
        non-chronological, often digressive, narrative. This completing-the-jigsaw-puzzle narrative style
        and Tom Hanks' amazing voice performance in the audiobook made it feel like Danny Conroy is
        sitting right next to you and telling his life's story. `,
        cover: 'https://covers.openlibrary.org/b/id/8921369-M.jpg',
    },
    {
        name: 'Between the World and Me',
        author: 'Ta-Nehisi Coates',
        description: `Between the World and Me is a moving letter from a father to a son that blends history,
        reminiscences, and the sage advice of a parent. Ta-Nehisi Coates recounts the history of violence and
        injustices towards African Americans and presents the realities of what it means to be black in the United States.
        Reading this book opened my eyes towards the generational trauma and systemic racism that African Americans have
        to deal with even today. Throughout the book, illustrative examples of how the society has valued black life
        differently and how the price of error is much higher for black people gave a visceral picture of racism in the US.
        This book showed me the very real barriers that exist between the world and black people.`,
        cover: 'https://covers.openlibrary.org/b/id/10118757-M.jpg',
    },
    {
        name: 'Where the Crawdads Sing',
        author: 'Delia Owens',
        description: `Set in the breathtaking marshes of North Carolina, Where the Crawdads Sing,
        is an emotive story of isolation, nature, human connection, love, and murder.
        Nature writer, Delia Owens, does a superb job in her debut novel by painting a vivid picture of the natural backdrop,
        which is central to the plot. The novel follows two, slowly intertwining timelines in Barkley Cove:
        the first is the coming of age story of an abandoned young girl, Kya, and the second is the investigation
        of the murder of popular man, Chase Andrews. The plot kept me hooked, and I loved
        the parallels between nature and human interaction. I highly recommend listening to an audiobook version,
        since the narrator Cassandra Campbell does a fantastic job by using North Carolina accents and by changing her
        voice for different characters, which makes it a great listening experience.`,
        cover: 'https://covers.openlibrary.org/b/id/8595579-M.jpg',
    },
    {
        name: 'Good Economics for Hard Times',
        author: 'Esther Duflo & Abhijit V. Banerjee',
        description: `The winners of the 2019 Nobel Memorial Prize in economic sciences,
        Esther Duflo and Abhijit V. Banerjee, elucidate in this book the methods and tools
        developmental economists use to tackle complex problems of today. Packed with
        evidence-backed insights into fundamental global issues such as immigration,
        trade, climate change, and employment, Good Economics for Hard Times makes a
        lot of economics research and academic ideas very accessible.  I particularly enjoyed
        the discussion on differing views of economists on predicting economic growth and
        why Duflo and Banerjee think that predicting economic growth is futile.
        Their unique perspectives on displacement effect of automation, universal basic income,
        flexicurity, high marginal tax rates, total factor productivity, and the dire
        need for a society that offers everyone dignity and respect also stood out to me.
        By discussing critical issues that directly or indirectly impact our lives,
        Banerjee and Duflo make a very strong case that economics is too important to be left to economists.`,
        cover: 'https://covers.openlibrary.org/b/id/9322264-M.jpg',
    },
    {
        name: 'Homo Deus',
        author: 'Yuval Noah Harari',
        description: `Yuval Noah Harari’s Homo Deus paints a stunning map of humanity’s path forward in
        our quest to become human gods. With discussions ranging from the onset of the anthropocene epoch
        to the new human agenda with immortality as a north star, Homo Deus is captivating, clear, and creative.
        I particularly enjoyed the sections on humanist religions and the ones on dataism - the notion that
        information flow is the supreme value and that data can determine what is right or wrong, and how democracy
        and free markets won because they improved the global data processing system. My two favorite lines in this
        book were:  “For the first time in history, more people die today from eating too much than too little,
        from old age than from infectious diseases, and more people commit suicide than are killed by soldiers,
        terrorists, and criminals put together” and “Our wildest dreams are still the product of organic chemistry."
        `,
        cover: 'https://covers.openlibrary.org/b/id/10357098-M.jpg',
    },
    {
        name: "The Innovator's Dilemma",
        author: 'Clayton Christensen',
        description: `Written by Harvard professor and businessman, Clayton Christensen, The
        Innovator's Dilemma is one of the six greatest business books of all
        time according to the Economist. This book introduces the idea of
        "disruptive innovation" and discusses in depth why great companies fail.
        Christensen describes several principles, theories and business concepts
        in a very simple and understandable way. For instance, he discusses
        Theory of Resource Dependence, Technology S-Curves, Value Networks and
        RPV Framework. Throughout the book, there are several examples of how
        big companies failed despite doing everything right and how smaller
        startups succeeded by pursuing disruptive innovation. There is also
        in-depth analysis of a few industries such as the disk drive industry.
        Though The Innovator's Dilemma is not a light read, I gained a lot of
        insight and learned several business concepts.`,
        cover: 'https://covers.openlibrary.org/b/id/9274687-M.jpg',
    },
    {
        name: 'The Undoing Project',
        author: 'Michael Lewis',
        description: `The Undoing Project tells the riveting story of the friendship of
        Israeli psychologist, Amos Tversky and Daniel Kahneman. Kahneman, a
        nobel laureate, and Tversky are credited with being the pioneers of
        behavioral economics and their seminal research papers changed the way
        how decision making and human judgement is studied. Michael Lewis gives
        a glimpse of a lot of brilliant ideas of Kahneman and Tversky such as
        Prospect Theory, Tversky Effect or the Linda Problem. He blends these
        academic ideas along with some historical context and the complex
        relationship of Kahneman and Tversky in a great narrative. I enjoyed the
        read and learned a few things about about human thought--such as humans
        are non-transitive and asymmetric thinkers, and they are conservative
        bayesians.`,
        cover: 'https://covers.openlibrary.org/b/id/9227956-M.jpg',
    },
    {
        name: 'Shoe Dog',
        author: 'Phil Knight',
        description: `Phil Knight's Shoe Dog is one of my favorite books of all time. It takes
        the reader on a gripping journey of how Nike started. Through highs and
        lows of Knight's life, Shoe Dog paints a vivid picture of passion,
        persistence, hardwork, and grit. Shoe Dog also gives a good taste of
        international trade, challenges of management, and ruthless competition.
        It was shocking to read about the numerous times Nike almost went
        bankrupt or the time when Knight could have gone to jail. Beautifully
        written and easy to follow, Shoe Dog showed me what real
        entrepreneurship looks like.`,
        cover: 'https://covers.openlibrary.org/b/id/8117311-M.jpg',
    },
    {
        name: 'The Art of Creative Thinking: 89 Ways to See Things Differently',
        author: 'Rod Judkins',
        description: `In The Art of Creative Thinking, Judkins attempts to capture the thought
        processes, work flows, inspirations and challenges of some of the most
        creative people in history. He reveals the out-of-the-box creative
        thinking that led to many scientific inventions, companies, famous
        architecture and paintings that are now household names. What's unique
        about this book is that it frequently defines a particular method of
        seeing things differently and then another method that completely
        opposes the earlier method, often on the very next page. Moreover, it
        can be read in a non-linear format by following the internal linking.
        Overall, Judkins provides a good understanding of where creativity stems
        from and how it can be nurtured in any human.`,
        cover:
            'https://images-na.ssl-images-amazon.com/images/I/516kLfyR5bL._SX365_BO1,204,203,200_.jpg',
    },
    {
        name: 'Freakonomics',
        author: 'Steven Levitt & Stephen Dubner',
        description: `Freakonomics is a very engaging work that presents an economist and a
        journalist's unconventional exploration of diverse subjects. Using
        unusual methods they identify and link seemingly disparate events and
        consequences, while backing their findings with real data. This book
        argues that understanding human incentives is key to numerous real-life
        riddles. Some of the topics which were particularly fascinating to me
        were teachers involved in cheating, socio-economic reasons &
        consequences of parents naming their children and how experts exploit
        information asymmetry. A definite page-turner, Freakonomics taught me
        how to think like a rogue economist and ask interesting and unorthodox
        questions.`,
        cover: 'https://covers.openlibrary.org/b/id/8641866-M.jpg',
    },
];

export default books;
