export const photos = [
    {
        src: 'website_photos/whitecoat',
        title: "Kyra's White Coat Ceremony",
        location: 'Mare Island, California',
        date: '2023',
    },
    {
        src: 'website_photos/flatirons',
        title: 'Flatirons',
        location: 'Boulder, Colorado',
        date: '2023',
    },
    {
        src: 'website_photos/lostlake',
        title: 'Lost Lake',
        location: 'Nederland, Colorado',
        date: '2023',
    },
    {
        src: 'website_photos/outsidelands',
        title: 'Outsidelands',
        location: 'Golden Gate Park, San Francisco',
        date: '2023',
    },
    {
        src: 'website_photos/rainier',
        title: 'Mt. Rainier',
        location: 'Mount Rainier National Park, Washington',
        date: '2023',
    },
    {
        src: 'website_photos/lakecrescent',
        title: 'Lake Crescent',
        location: 'Olympic National Park, Washington',
        date: '2023',
    },
    {
        src: 'website_photos/hallofmosses',
        title: 'Hall of Mosses',
        location: 'Olympic National Park, Washington',
        date: '2023',
    },
    {
        src: 'website_photos/kilauea',
        title: 'Kilauea Volcano',
        location: "Volcanoes National Park, Hawai'i",
        date: '2023',
    },
    {
        src: 'website_photos/waipio',
        title: "Waipi'o Lookout",
        location: "Big Island, Hawai'i",
        date: '2023',
    },
    {
        src: 'website_photos/kahaluu',
        title: "Kahalu'u Beach",
        location: "Big Island, Hawai'i",
        date: '2023',
    },
    {
        src: 'website_photos/rome',
        title: 'A Roman Sunset',
        location: 'Rome, Italy',
        date: '2023',
    },
    {
        src: 'website_photos/florence',
        title: 'A Florentine Golden Hour',
        location: 'Florence, Italy',
        date: '2023',
    },
    {
        src: 'website_photos/napoli',
        title: 'A Neapolitan Blue Hour ft Mt. Vesuvius',
        location: 'Naples, Italy',
        date: '2023',
    },
    {
        src: 'website_photos/vatican',
        title: "Strolling by St. Peter's Basilica",
        location: 'Vatican City',
        date: '2023',
    },
    {
        src: 'website_photos/charminar',
        title: 'Charminar',
        location: 'Hyderabad, India',
        date: '2023',
    },
    {
        src: 'website_photos/family1',
        title: 'Family',
        location: 'New Delhi, India',
        date: '2023',
    },
    {
        src: 'website_photos/rashtrapati_bhavan',
        title: 'Rashtrapati Bhavan',
        location: 'New Delhi, India',
        date: '2023',
    },
    {
        src: 'website_photos/yosemite_friends',
        title: 'Yosemite with school friends',
        location: 'Yosemite, California',
        date: '2022',
    },
    {
        src: 'website_photos/yosemite',
        title: 'Mist Trail',
        location: 'Yosemite, California',
        date: '2022',
    },
    {
        src: 'website_photos/encinitas',
        title: 'Encinitas',
        location: 'California',
        date: '2022',
    },
    {
        src: 'website_photos/craterswim',
        title: 'Crater Lake',
        location: 'Oregon',
        date: '2022',
    },
    {
        src: 'website_photos/craterlake',
        title: 'Crater Lake',
        location: 'Oregon',
        date: '2022',
    },
    {
        src: 'website_photos/lisbon',
        title: 'Lisbon',
        location: 'Portugal',
        date: '2022',
    },
    {
        src: 'website_photos/duoro',
        title: 'Duoro River',
        location: 'Porto, Portugal',
        date: '2022',
    },
    {
        src: 'website_photos/porto',
        title: 'Porto',
        location: 'Portugal',
        date: '2022',
    },
    {
        src: 'website_photos/sagradafamilia',
        title: 'La Sagrada Familia',
        location: 'Barcelona, Spain',
        date: '2022',
    },
    {
        src: 'website_photos/tetons',
        title: 'Grand Teton National Park',
        location: 'Wyoming',
        date: '2021',
    },
    {
        src: 'website_photos/yellowstone',
        title: 'Upper Falls',
        location: 'Yellowstone National Park',
        date: '2021',
    },
    {
        src: 'website_photos/bozeman',
        title: 'A Cottage in Bozeman',
        location: 'Bozeman, Montana',
        date: '2021',
    },
    {
        src: 'website_photos/rockies',
        title: 'The Rockies',
        location: 'Rocky Mountain National Park, Colorado',
        date: '2021',
    },
    {
        src: 'website_photos/denver',
        title: 'The Colorado State Capitol',
        location: 'Denver, Colorado',
        date: '2021',
    },
    {
        src: 'website_photos/parthenon',
        title: 'The Parthenon of Nashville',
        location: 'Nashville, Tennessee',
        date: '2021',
    },
    {
        src: 'website_photos/smokies',
        title: 'Surreal Smokies',
        location: 'Great Smoky Mountain National Park, Tennesse - North Carolina border',
        date: '2021',
    },
    {
        src: 'website_photos/charleston',
        title: 'A Charleston Sunset',
        location: 'Isle of Palms, South Carolina',
        date: '2021',
    },
    {
        src: 'website_photos/charlestonBird',
        title: 'East Coast Skies',
        location: 'Isle of Palms, South Carolina',
        date: '2021',
    },
    {
        src: 'website_photos/atlanta',
        title: 'Altanta Skyline',
        location: 'Atlanta, Georgia',
        date: '2021',
    },
    {
        src: 'website_photos/austin',
        title: 'An afternoon in Austin',
        location: 'Austin, Texas',
        date: '2021',
    },
    {
        src: 'website_photos/whitesands',
        title: 'White Sands National Park',
        location: 'New Mexico',
        date: '2021',
    },
    {
        src: 'website_photos/hoover',
        title: 'Hoover Dam',
        location: 'Arizona-Nevada Border',
        credits: 'Ankur Papneja',
        date: '2021',
    },
    {
        src: 'website_photos/alcatraz',
        title: 'Alcatraz',
        location: 'San Francisco',
        date: '2020',
    },
    {
        src: 'website_photos/coyotePark',
        title: 'Coyote Park',
        location: 'San Mateo, CA',
        date: '2020',
    },
    {
        src: 'website_photos/notredame',
        title: 'Notre Dame',
        location: 'Paris, France',
        date: '2019',
    },
    {
        src: 'website_photos/grad1',
        title: 'Graduation',
        location: 'UCLA',
        credits: 'Stanley Wu',
        date: '2019',
    },
    {
        src: 'website_photos/grad2',
        title: 'Graduation',
        location: 'UCLA',
        credits: 'Stanley Wu',
        date: '2019',
    },
    {
        src: 'website_photos/ucla_commencement',
        title: 'Commencement',
        location: 'UCLA',
        credits: '',
        date: '2019',
    },
    {
        src: 'website_photos/engineers_ball',
        title: 'Engineers Ball',
        location: 'Pauley Pavilion, UCLA',
        credits: '',
        date: '2018',
    },
    {
        src: 'website_photos/lahacks',
        title: 'LA Hacks Organizing Team',
        location: 'Pauley Pavilion, UCLA',
        credits: '',
    },
    {
        src: 'website_photos/clfounders',
        title: 'UCLA Creative Labs - Founding Team',
        credits: '',
        date: '2017',
    },
    {
        src: 'website_photos/FuturizeX2',
        title: 'Futurize X',
        location: 'Powell Library, UCLA',
        credits: '',
        date: '2017',
    },
];
