import React from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage, lazyload, placeholder } from '@cloudinary/react';
import { Resize } from '@cloudinary/url-gen/actions/resize';

export const cld = new Cloudinary({
    cloud: {
        cloudName: 'dbnu2xllj',
    },
});

export const CloudinaryPhoto = (props) => {
    const width = props.width || 1000;
    const photo = cld.image(props.name);
    photo.resize(Resize.scale().width(width)).format('auto').quality('auto');
    return <AdvancedImage cldImg={photo} plugins={[lazyload(), placeholder({ mode: 'blur' })]} />;
};

export const CloudinaryPhotoGalleryView = (props) => {
    const photo = cld.image(props.name);
    photo.format('auto').quality('auto');
    return <AdvancedImage cldImg={photo} plugins={[placeholder({ mode: 'blur' })]} />;
};
