import '../css/App.scss';
import '../css/photoComponents.scss';
import { CloudinaryPhotoGalleryView, CloudinaryPhoto } from '../utils/cloudinary';
import { useWindowDimensions, isMobile } from '../utils/detection';

import React from 'react';

export const GalleryPhoto = (props) => {
    const { width } = useWindowDimensions();

    const galleryPhotoWidth = isMobile ? width : 0.7 * width;
    return (
        <div className="gallery-photo">
            <CloudinaryPhotoGalleryView name={props.url} width={galleryPhotoWidth} />
            <div className="gallery-photo-description">
                <div className="gallery-photo-title">{props.title}</div>
                {props.photo.location && (
                    <div className="gallery-photo-location">
                        {props.photo.location}
                        {props.photo.date && ` | ${props.photo.date}`}
                    </div>
                )}
            </div>
        </div>
    );
};

export const GridPhoto = (props) => {
    const baseClassName = isMobile ? 'grid-photo-mobile' : 'grid-photo';
    return (
        <div
            className={`${baseClassName} ${props.id}`}
            key={props.id}
            onClick={() => props.setImageInViewer(props.photo)}>
            {!isMobile && props.photo.title && (
                <div className="grid-photo-description-overlay">
                    <div className="grid-photo-title">{props.photo.title}</div>
                    {props.photo.location && (
                        <div className="grid-photo-location">
                            {props.photo.location}
                            {props.photo.date && ` | ${props.photo.date}`}
                        </div>
                    )}
                </div>
            )}
            <CloudinaryPhoto
                key={props.id}
                name={props.photo.src}
                alt={props.photo.title || ''}
                loading={props.index < 6 ? 'auto' : 'lazy'}
                width={props.photosWidth}
            />
            {isMobile && props.photo.title && (
                <div className={`${baseClassName}-description`}>
                    <div className={`${baseClassName}-title`}>{props.photo.title}</div>
                    {props.photo.location && (
                        <div className={`${baseClassName}-location`}>
                            {props.photo.location}
                            {props.photo.date && ` | ${props.photo.date}`}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
