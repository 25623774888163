import React, { Component } from 'react';
import '../css/writes.scss';
import Transition from '../components/Transition';
import Menu from '../components/Menu';
import sr from '../utils/ScrollRevealMod.js';
import writes from '../constants/writes';

class Writes extends Component {
    componentDidMount() {
        const scroll_config = {
            origin: 'bottom',
            duration: 700,
            delay: 100,
            distance: '100px',
            scale: 1,
            easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
            mobile: true,
            reset: false,
            useDelay: 'once',
        };

        sr.reveal('.writes-section>*', scroll_config);
    }

    render() {
        return (
            <div className="App">
                <Menu />
                <Transition name="WRITES" />
                <div className="writes-container">
                    <div className="writes-content">
                        <h1>Writes.</h1>
                        <div className="writes-section">
                            <p>
                                I like writing and I hope to write more. Here are a few things I've
                                written:
                            </p>
                            <div className="write-link">
                                {writes.map((article, i) => {
                                    return (
                                        <>
                                            <a
                                                href={article.url}
                                                rel="noopener noreferrer"
                                                target="_blank">
                                                <span>{article.title}</span>
                                            </a>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>

                            <p>
                                I've been featured in other writings. Couldn't find a better place
                                to link these, so here they are:
                            </p>

                            <div className="write-link">
                                <a
                                    href="http://dailybruin.com/2017/01/12/creative-labs-connects-design-coding-in-real-world-projects/"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        Creative Labs club connects design, coding in real-world
                                        projects | Daily Bruin
                                    </span>
                                </a>
                                <br />

                                <a
                                    href="http://dailybruin.com/2017/04/05/three-undergraduates-to-speak-at-tedxucla-in-may/"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        Three undergraduates to speak at TEDxUCLA in May | Daily
                                        Bruin
                                    </span>
                                </a>
                                <br />

                                <a
                                    href="https://medium.com/@anshulabula/day-8-mihir-mathur-533574081923"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>Day #8: Mihir Mathur | Anshul Aggarwal</span>
                                </a>
                                <br />
                            </div>
                        </div>

                        <div className="writes-section">
                            <p>
                                I've also written about art, music and film for some classes I took
                                at UCLA. I did research and invested a lot of time, effort and
                                thought for these works so it would be rather sad if only professors
                                or TAs could read it. So here they are:
                                <br />
                                <i className="small">
                                    (Note: All these works were submitted through TurnItIn, so
                                    plagiarizing would be stupid.)
                                </i>
                            </p>

                            <h6 className="write-section-title">Art History 23: Modern Art</h6>
                            <div className="write-link">
                                <a
                                    href="https://docs.google.com/document/d/1l7C76Zu8UNglPOTzk0MLyi3aaQKmhwf5YC34lKoI8I4/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        Formal Analysis and Comparison of The Walking Man and
                                        T.E.U.C.L.A
                                    </span>
                                </a>
                                <br />

                                <a
                                    href="https://docs.google.com/document/d/1p_4fCuHcdVn1Ci5Esyqiap2Ts55WVkjyD5zucJjp9lA/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        Formal Analysis of Le Havre, Bâteaux de Pêche Sortant du
                                        Port and Portrait of Sebastian Juñer Vidal
                                    </span>
                                </a>
                            </div>

                            <h6 className="write-section-title">Music 15: The Art of Listening</h6>

                            <div className="write-link">
                                <a
                                    href="https://docs.google.com/a/g.ucla.edu/document/d/1WoLm_YGIwC4iLNtqxxqAhmZSCoyWAJZGcAJCaux5nDY/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>Concert Report: Bamberg Symphony Orchestra</span>
                                </a>
                                <br />
                                <a
                                    href="https://docs.google.com/a/g.ucla.edu/document/d/1rkupRS0oXAOszpYrcYohEsbIJVYvqzbcmkfbTWOlPrg/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>Concert Report: Big Band Jazz Concert</span>
                                </a>

                                <br />
                                <a
                                    href="https://docs.google.com/a/g.ucla.edu/document/d/1Gzn0MNoq4FpCIqIqnEv5TTQ5abo9dGHS0J1KBtkqB9Q/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>Concert Report: Cendrillon Opera</span>
                                </a>
                            </div>

                            <h6 className="write-section-title">
                                English Composition 3: Composition, Rhetoric, and Language.
                            </h6>

                            <div className="write-link">
                                <a
                                    href="https://docs.google.com/document/d/1SLy50moxOMljaTzGEbDHGwQSZoBJYXEMDb2OK9AJRdc/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        Forrest Gump: Evolution of Southern Americans’ beliefs about
                                        race and disability in Mid 20th Century
                                    </span>
                                </a>
                                <br />
                                <a
                                    href="https://docs.google.com/document/d/1JuozF29ZsqhWjJhJZrYNMvru6HdrYIOJDj_ebGv7_lI/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        Shawshank Redemption: Dichotomy of the Virtuous Convict
                                    </span>
                                </a>

                                <br />
                                <a
                                    href="https://docs.google.com/document/d/1B5qOG8ruNegMZdPBAzZFslwfme8Kv_1NbNwuu0KER2A/edit?usp=sharing"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>
                                        American Sniper | Shutter Island: The Effects of Post-War
                                        Trauma
                                    </span>
                                </a>
                            </div>
                        </div>
                        <p>
                            <i>Stay tuned for more of my writing!</i>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Writes;
